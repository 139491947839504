.notifications-link {
  position: relative;
  display: inline-block;

  .notifications-link-badge {
    position: absolute;
    top: -1px;
    right: -11px;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    display: flex;
    font-size: 7pt;
    border-radius: 50%;
    background: $primary;
    color: $text-contrast;
  }

  &[data-active=false] .notifications-link-badge {
    visibility: hidden;
  }

  &[data-active=true] ~ .hamburger-only {
    margin-left: 0.5em;
  }
}

.notification-card-info {
  background-color: #cce5ff;

  .feed-card-header {
    color: #004085;
  }
}

.notification-card-success {
  background-color: #d4edda;

  .feed-card-header {
    color: #155724;
  }
}

.notification-card-warning {
  background-color: #fff3cd;

  .feed-card-header {
    color: #856404;
  }
}

.notification-card-error {
  background-color: #f8d7da;

  .feed-card-header {
    color: #721c24;
  }
}
