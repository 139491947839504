:root {
  --piral-theme-primary: #56AA1C;
  --piral-theme-secondary: #78bb49;
  --piral-theme-tertiary: #745a13;
  --piral-theme-gray: #e1e3e5;
  --piral-theme-text-foreground: #101010;
  --piral-theme-text-background: #fbfbfb;
  --piral-theme-text-contrast: #fff;
  --piral-theme-danger: #d61c1c;
  --piral-theme-success: #499e0d;
  --piral-theme-info: #1D72F3;
  --piral-theme-warning: #FFC007;
  --piral-theme-shadow: #f2f2f2;
  --piral-theme-primary-lighter: color-mix(in srgb, var(--piral-theme-primary), white 10%);
  --piral-theme-secondary-lighter: color-mix(in srgb, var(--piral-theme-secondary), white 10%);
  --piral-theme-tertiary-lighter: color-mix(in srgb, var(--piral-theme-tertiary), white 10%);
  --piral-theme-gray-lighter: color-mix(in srgb, var(--piral-theme-gray), white 70%);
  --piral-theme-gray-medium: color-mix(in srgb, var(--piral-theme-gray), black 10%);
  --piral-theme-gray-darker: color-mix(in srgb, var(--piral-theme-gray), black 20%);
  --piral-theme-gray-faded: color-mix(in srgb, var( --piral-theme-gray-darker), transparent 30%);
  --piral-theme-text-faded: color-mix(in srgb, var( --piral-theme-text-contrast), transparent 13%);
  --piral-theme-danger-lighter: color-mix(in srgb, var(--piral-theme-danger), white 10%);
  --piral-theme-success-lighter: color-mix(in srgb, var(--piral-theme-success), white 10%);
  --piral-theme-info-lighter: color-mix(in srgb, var(--piral-theme-info), white 10%);
  --piral-theme-warning-lighter: color-mix(in srgb, var(--piral-theme-warning), white 10%);

  --piral-font-family-normal: 'Lato', sans-serif;
  --piral-font-family-heading: Montserrat, sans-serif;
}

$primary: var(--piral-theme-primary);
$primary-lighter: var(--piral-theme-primary-lighter);
$secondary: var(--piral-theme-secondary);
$secondary-lighter: var(--piral-theme-secondary-lighter);
$tertiary: var(--piral-theme-tertiary);
$tertiary-lighter: var(--piral-theme-tertiary-lighter);
$gray: var(--piral-theme-gray);
$gray-lighter: var(--piral-theme-gray-lighter);
$gray-darker: var(--piral-theme-gray-darker);
$gray-medium: var(--piral-theme-gray-medium);
$gray-faded: var(--piral-theme-gray-faded);
$text-foreground: var(--piral-theme-text-foreground);
$text-background: var(--piral-theme-text-background);
$text-contrast: var(--piral-theme-text-contrast);
$text-faded: var(--piral-theme-text-faded);
$danger: var(--piral-theme-danger);
$danger-lighter: var(--piral-theme-danger-lighter);
$success: var(--piral-theme-success);
$success-lighter: var(--piral-theme-success-lighter);
$info: var(--piral-theme-info);
$info-lighter: var(--piral-theme-info-lighter);
$warning: var(--piral-theme-warning);
$warning-lighter: var(--piral-theme-warning-lighter);
$shadow: var(--piral-theme-shadow);
$font-family-normal: var(--piral-font-family-normal);
$font-family-heading: var(--piral-font-family-heading);

@import '~@smapiot/components/style/all';

@import './general';
@import './notifications';
@import './tags';
