.pilet-table-tags-dropdown {
  position: absolute;
  top: -80px;
  width: 140px;
  
  @media screen and (max-width: 550px) {
    & {
      position: static;
      width: auto;
    }
  }

  .form-group {
    height: 60px;
  }
}
