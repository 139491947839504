$fontRoot: '../../../node_modules/@smapiot/components/fonts';

@import '~@piral/portal-core/styles/index.scss';

.not-logged-in {
  #app {
    padding-top: 0 !important;
  }

  .container {
    margin: auto;
  }
}

.login.login-buttons {
  margin-inline: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.boxes.teasers {
  flex-wrap: wrap;
  align-items: center;
}

.footer-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  > i {
    font-size: 1rem;
  }
}
