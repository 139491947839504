.more-info {
  margin-top: 4em;
}

.color-selected {
  color: $warning;
}

.content > section:last-child {
  flex: 1;
}

.limited-width {
  justify-content: center;
  display: flex;
}

#app > footer > .container, #app .limited-width {
  width: 100%;

  @media (min-width: $tablet-brk) {
    max-width: 740px;
  }

  @media (min-width: $desktop-brk) {
    max-width: 1100px;
  }
}

.empty-teaser {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  max-Width: 500px;
  text-align: center;

  & > .btn {
    padding-block: 0.8rem;
  }
}

.modal.unauthorized-overlay {
  display: flex;
  z-index: 100000;
}
